import React, { Component } from 'react'

export default class Title extends Component {
  render() {
    return (
      <b> <pre>
      {decodeURI("%20..%7C'''.%7C%20%20%20%20%20%20%20%20%20%20%20%20%20%20%20%20%20%20%20%20%20%20%20%20%20%20%20%20%20%20%20%20%20%20%20%20%20%20%20.'%7C.%20'%7C%7C'%20%20%20%20%20%20%20%7C%7C%20%20%20%20.'%7C.%20%20%20%20%20%20%20%20%20%0A.%7C'%20%20%20%20%20'%20%20%20....%20%20%20..%20..%20..%20%20%20%20%20....%20%20%20%20...%20%20%20.%7C%7C.%20%20%20%20%7C%7C%20%20%20%20%20%20%20...%20%20.%7C%7C.%20%20%20%20%20....%20%20%0A%7C%7C%20%20%20%20....%20''%20.%7C%7C%20%20%20%7C%7C%20%7C%7C%20%7C%7C%20%20.%7C...%7C%7C%20.%7C%20%20'%7C.%20%20%7C%7C%20%20%20%20%20%7C%7C%20%20%20%20%20%20%20%20%7C%7C%20%20%20%7C%7C%20%20%20%20.%7C...%7C%7C%20%0A'%7C.%20%20%20%20%7C%7C%20%20.%7C'%20%7C%7C%20%20%20%7C%7C%20%7C%7C%20%7C%7C%20%20%7C%7C%20%20%20%20%20%20%7C%7C%20%20%20%7C%7C%20%20%7C%7C%20%20%20%20%20%7C%7C%20%20%20%20%20%20%20%20%7C%7C%20%20%20%7C%7C%20%20%20%20%7C%7C%20%20%20%20%20%20%0A%20''%7C...'%7C%20%20'%7C..'%7C'%20.%7C%7C%20%7C%7C%20%7C%7C.%20%20'%7C...'%20%20'%7C..%7C'%20.%7C%7C.%20%20%20.%7C%7C.....%7C%20.%7C%7C.%20.%7C%7C.%20%20%20%20'%7C...'%20")}
     </pre></b>
     
    )
  }
}
